import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Button } from 'react-bootstrap'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { SlugLink } from '../components/utils'
//import Img from "gatsby-image"
//import { rhythm, scale } from "../utils/typography"

class BlogPostTemplate extends React.Component {

  render() {
    const post = this.props.data.privateGraphql.getnew
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.title}
          description={post.short}
          image={`${post.image}`}
          isBlogPost={true}
          datePublished={post.publishDate}
        />
        <article className="w-layout-container--narrow w-post-content">
          <header className="w-article-header">
            <h1 className="w-article-header__headline">{post.title}</h1>
            <div className="w-author__published">
              <time>{post.publishDate}</time>
            </div>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.detail }} />
          <div className="w-card__chips w-chips">
            {post.tags !== null ?
              post.tags.split(',').map(tagx => {
                if (tagx !== "") return (
                  <Link to={`/tags/${SlugLink(tagx)}`} className="w-chip" key={tagx}>{tagx}</Link>
                )
              })
              :
              null
            }
          </div>
          <footer>
            {/* <Bio /> */}
          </footer>
        </article>
        <div className="container blog-relate">
          <Row>
            <Col xs={12} md={6} className="item-relate">
              {previous && (
                <div className="ref-prev">
                  <Link to={previous.slugtitle} title={previous.title} rel="prev">
                    <svg height="24px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-double-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-angle-double-left fa-w-10 fa-3x"><path fill="currentColor" d="M153.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L192.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L153 264.5c-4.6-4.7-4.6-12.3.1-17zm-128 17l117.8 116c4.7 4.7 12.3 4.7 17 0l7.1-7.1c4.7-4.7 4.7-12.3 0-17L64.7 256l102.2-100.4c4.7-4.7 4.7-12.3 0-17l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L25 247.5c-4.6 4.7-4.6 12.3.1 17z" className=""></path></svg>
                    {previous.title}
                  </Link>
                </div>
              )}

            </Col>
            <Col xs={12} md={6} className="item-relate">
              {next && (
                <div className="ref-next">
                  <Link to={next.slugtitle} title={next.title} rel="next">
                    {next.title}
                    <svg height="24px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-double-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-angle-double-right fa-w-10 fa-3x"><path fill="currentColor" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17zm128-17l-117.8-116c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17L255.3 256 153.1 356.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l117.8-116c4.6-4.7 4.6-12.3-.1-17z" className=""></path></svg>
                  </Link>
                </div>
              )}
            </Col>
          </Row>
        </div>
        {/* <article>
          <header>
            <h1
              style={{
                marginBottom: 0,
              }}
            >
              {post.title}
            </h1>
            <p
              style={{
                display: `block`,
              }}
            >
              {post.datePublished}
            </p>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.detail }} />
          <div>
            {post.tags !== null ?
              post.tags.split(',').map(tagx => (
                <Link to={`/tags/${SlugLink(tagx)}`} style={{ marginLeft: 5, color: '#FFF' }} key={tagx}><Button variant="dark" style={{margin: 2}}>{tagx}</Button></Link>
              ))
              :
              null
            }
          </div>
          <div className="clear-fix"></div>
          <hr
            style={{
            }}
          />
          <footer>
          </footer>
        </article> */}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ($slug: String = "404") {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    privateGraphql{
      getnew(slugtitle: $slug ) {
        id
        detail
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
      }
    }
  }
`
